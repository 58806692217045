<template>
  <v-container>
    <v-card>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-card-title class="headline">
            {{ $t("nav.help") }} - {{ $t("nav.what-to-do").toLowerCase() }}
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <custom-button
            href="https://drive.google.com/file/d/187VgqmjujSrvpqSreh1sVNovNv9vZKNI/view?usp=sharing"
            target="_blank"
            block
            :text="`MOK ${$t('global.security-protocols')}`"
            icon="open_in_new"
          />
        </v-col>
        <v-col cols="12">
          <custom-button
            href="https://drive.google.com/file/d/1ASpYjw0joIbOmwVOrLIb5c90aD3erlVg/view?usp=sharing"
            target="_blank"
            block
            :text="`${$t('nav.big-family-house')} ${$t(
              'global.security-protocols'
            )}`"
            icon="open_in_new"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
export default {
  components: { CustomButton },
};
</script>

<style></style>
